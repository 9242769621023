import React from 'react';
import { CtaSectionExtended } from 'modules/cta-section-extended';

export const BlockchainCtaSectionExtended = () => {
    return (
        <CtaSectionExtended title="ctaExtendedTitle">
            <p>
                Unlock new opportunities with secure, transparent, and
                innovative blockchain solutions and NFT marketplaces tailored to
                your needs.
            </p>
            <ul>
                <li>
                    Free consultation for custom blockchain and NFT solutions
                </li>
                <li>
                    Discover how blockchain can enhance security and
                    transparency in your operations
                </li>
                <li>
                    Expertise in developing NFT marketplaces, smart contracts,
                    and decentralized applications
                </li>
                <li>Boost user engagement with cutting-edge NFT experiences</li>
                <li>
                    Transform your business with innovative blockchain
                    technology
                </li>
            </ul>
        </CtaSectionExtended>
    );
};
